<template>
    <div>
        <label v-if="title !== null">{{title}}</label>
        <b-row>
            <b-col>
                <multiselect class="form-control---"
                    v-model="input_countries"
                    :options="countryList"
                    label="name"
                    track-by="code4"
                    :multiple="multiple"
                    :close-on-select="closeOnSelect"
                    :clear-on-select="false"
                    :hide-selected="hideSelected"
                    :placeholder="placeholder"
                    :showLabels="false"
                    :showNoResults="false"
                    @input="onInput"
                    @close="onClose"
                    :limit="limit"
                    :max="max"
                    >
                        <!-- <template slot="clear" slot-scope="props">
                            <span class="multiselect__clear" v-if="input_countries && input_countries.length" @mousedown.prevent.stop="clearAllCountrySelect(props.search)"></span>
                        </template> -->

                        <!-- Pass on all named slots -->
                        <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
                        <!-- Pass on all scoped slots -->
                        <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope"><slot :name="slot" v-bind="scope"/></template>

                    </multiselect>
                </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    props: {
        value: [Array, String, Object],
        title: { type: String, default: null },
        allCountries: Array,
        multiple: {type:Boolean, default:true},
        addAllCountriesItem: {type:Boolean, default:false},
        'close-on-select': {type:Boolean, default:false},
        startOpen: {type:Boolean, default:false},
        placeholder: { type: String, default: "Select countries" },
        hideSelected: { type: Boolean, default: true },
        limit: { type: Number, default: 99999 },
        max: { type: Number, default: undefined },
    },
    data() {
        return {
            input_countries: this.multiple ? [...(this.value || [])] : this.value,
        }
    },
    computed: {
        countryList() {
            return this.addAllCountriesItem ?
                [{code4: '*', name: '- All Countries -'}].concat(this.allCountries) :
                this.allCountries;
        }
    },
    mounted() {
        if (this.startOpen) {
            const elInput = this.$el.querySelector('.multiselect .multiselect__input');
            if (elInput) elInput.focus();
        }
    },
    watch: {
        value() {
            this.input_countries = this.multiple ? [...(this.value || [])] : this.value;
        },
    },
    methods: {
        onInput(value) {
            // console.log('countries input', value);
            let allSelected = false;
            if (this.multiple) {
                allSelected = value.find(c => c.code4 === '*');
                if (allSelected) {
                    this.$emit('input', this.allCountries);
                }
            } else {
                allSelected = value && value.code4 === '*';
                if (allSelected) {
                    this.$emit('input', null);
                }
            }
            if (!allSelected) {
                this.$emit('input', this.input_countries);
            }
        },
        onClose() {
            this.$emit('close');
        },
        // clearAllCountrySelect() {
        //     this.input_countries = [];
        // },
    }
}
</script>
