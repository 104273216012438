import bearer from '@websanova/vue-auth/drivers/auth/bearer'
import axios from '@websanova/vue-auth/drivers/http/axios.1.x'
import router from '@websanova/vue-auth/drivers/router/vue-router.2.x'
import store from './store'

/**
 * Authentication configuration, some of the options can be override in method calls
 */

// axios.interceptors.response.use(undefined, function (err) {
//   console.log(err);
//   /*
//   if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
//       return getRefreshToken()
//       .then(function (success) {
//           setTokens(success.access_token, success.refresh_token) ;                   
//           err.config.__isRetryRequest = true;
//           err.config.headers.Authorization = 'Bearer ' + getAccessToken();
//           return axios(err.config);
//       })
//       .catch(function (error) {
//           console.log('Refresh login error: ', error);
//           throw error;
//       });
//   }
//   throw err;
//   */
// });

////////////////////////////////////////////////
// // Add a request interceptor
// axios.interceptors.request.use(function (config) {
//     // Do something before request is sent
//     return config;
//   }, function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   });
// // Add a response interceptor
// axios.interceptors.response.use(function (response) {
//     // Do something with response data
//     return response;
//   }, function (error) {
//     // Do something with response error
//     return Promise.reject(error);
//   });

const config = {
  auth: bearer,
  http: axios,
  router: router,
  tokenDefaultName: 'grt-token',
  tokenStore: ['localStorage'],
  rolesVar: 'role', //User model field which contains the user role details.

  // API endpoints used in Vue Auth.
  registerData: {
    url: 'auth/register', 
    method: 'POST', 
    redirect: '/login'
  },
  loginData: {
    url: 'auth/login', 
    method: 'POST', 
    redirect: '',
    fetchUser: true
  },
  logoutData: {
    url: 'auth/logout', 
    method: 'POST', 
    redirect: '/login', 
    makeRequest: true
  },
  fetchData: {
    url: 'auth/user', 
    method: 'GET', 
    enabled: true
  },
  refreshData: {
    url: 'auth/refresh', 
    method: 'GET', 
    enabled: true, 
    interval: 30
  },
  parseUserData: function (response) {
    //console.log('parseUserData');
    //console.log(response);
    if (!response.error) {
        if (response && response.user) {
            store.commit('changeUserData', response.user);
            return response.user;
        }
    }
    store.commit('changeUserData', null);
    return null;
  }
}
export default config
