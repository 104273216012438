import Vue from 'vue'
import VueRouter from 'vue-router'
// Pages
import store from './store'
// Routes

Vue.use(VueRouter)

const routes = [
    {
        path: '/welcome',
        name: 'welcome',
        component: () => import(/* webpackChunkName: "group1" */ './pages/Welcome'),
        meta: {
            //auth: false,
            title: "Welcome"
        }
    },
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "group1" */ './pages/Approval'),
        meta: {
            //auth: false,
            title: "Home"
        }
    },
    {
        path: '/home',
        beforeEnter: (to, from, next) => {
            return next({ name: 'home' });
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "group1" */ './pages/Login'),
        meta: {
            //auth: false,
            title: "Login"
        }
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import(/* webpackChunkName: "group1" */ './pages/Logout'),
        meta: {
            title: "Logout"
        }
    },
    {
        path: '/password-forgotten',
        name: 'password-forgotten',
        component: () => import(/* webpackChunkName: "group1" */ './pages/PasswordForgotten'),
        meta: {
            title: "Forgot Password",
            auth: false
        }
    },
    {
        path: '/reset-password/:token',
        name: 'password-reset',
        component: () => import(/* webpackChunkName: "group1" */ './pages/PasswordReset'),
        meta: {
            title: "Reset Password",
            auth: false
        }
    },
    {
        path: '/password-change',
        name: 'password-change',
        component: () => import(/* webpackChunkName: "group1" */ './pages/PasswordChange'),
        meta: {
            title: "Change Password",
            auth: true
        }
    },
    {
        path: '/users',
        name: 'users',
        component: () => import(/* webpackChunkName: "group1" */ './pages/Users'),
        meta: {
            title: "Manage Users",
            auth: true
        }
    },
    {
        path: '/users/create',
        name: 'user-create',
        component: () => import(/* webpackChunkName: "group1" */ './pages/AddUser'),
        meta: {
            title: "Add User",
            //auth: ['manage users'],
            auth: true
        }
    },
    {
        path: '/users/:id',
        name: 'user-edit',
        component: () => import(/* webpackChunkName: "group1" */ './pages/EditUser'),
        meta: {
            title: "Edit User",
            //auth: ['manage users'],
            auth: true
        }
    },
    {
        path: '/import/eclass',
        name: 'import-eclass',
        component: () => import(/* webpackChunkName: "group1" */ './pages/ImportEclass'),
        meta: {
            title: "Import Eclass",
            //auth: ['manage users'],
            auth: true
        }
    },
    {
        path: '/export/eclass',
        name: 'export-eclass',
        component: () => import(/* webpackChunkName: "group1" */ './pages/ExportEclass'),
        meta: {
            title: "Export Eclass",
            //auth: ['manage users'],
            auth: true
        }
    },
    {
        path: '/brands/:id?',
        name: 'brands',
        component: () => import(/* webpackChunkName: "group1" */ './pages/Brands'),
        meta: {
            title: "Manage brands",
            auth: true
        }
    },
    {
        path: '/loyalties/:id?',
        name: 'loyalties',
        component: () => import(/* webpackChunkName: "group1" */ './pages/Loyalties'),
        meta: {
            title: "Manage loyalty programs",
            auth: true
        }
    },
    {
        path: '/overview/brands',
        name: 'overview-brands',
        component: () => import(/* webpackChunkName: "group1" */ './pages/OverviewBrands'),
        meta: {
            title: "Brands Overview",
            auth: true,
            wcSelect: false,
        }
    },
    {
        path: '/overview/variants',
        name: 'overview-variants',
        component: () => import(/* webpackChunkName: "group1" */ './pages/OverviewVariants'),
        meta: {
            title: "Variants Overview",
            auth: true,
            wcSelect: false,
        }
    },
    {
        path: '/overview/loyalties',
        name: 'overview-loyalties',
        component: () => import(/* webpackChunkName: "group1" */ './pages/OverviewLoyalties'),
        meta: {
            title: "Loyalty Programmes Overview",
            auth: true,
            wcSelect: false,
        }
    },
    {
        path: '/overview/approval',
        name: 'overview-approval',
        component: () => import(/* webpackChunkName: "group1" */ './pages/Approval'),
        meta: {
            title: "Markets Approval Overview",
            auth: true
        }
    },
    {
        path: '/quota',
        name: 'quota',
        component: () => import(/* webpackChunkName: "group1" */ './pages/Quota'),
        meta: {
            title: "Quota",
            auth: true
        }
    },
    {
        path: '/target',
        name: 'target',
        component: () => import(/* webpackChunkName: "group1" */ './pages/Target'),
        meta: {
            title: "Market Summary",
            auth: true
        }
    },
    {
        path: '/translations/:dataset?',
        name: 'translations',
        component: () => import(/* webpackChunkName: "group1" */ './pages/Translations'),
        meta: {
            title: "Translations",
            auth: true
        }
    },
    {
        path: '/import/other_text_update',
        name: 'translations_import',
        component: () => import(/* webpackChunkName: "group1" */ './pages/TranslationsImportUpdate'),
        meta: {
            title: "Import other texts",
            auth: true
        }
    },
    {
        path: '/import/other_text_new',
        name: 'translations_import',
        component: () => import(/* webpackChunkName: "group1" */ './pages/TranslationsImportNew'),
        meta: {
            title: "Import other texts",
            auth: true
        }
    },
    {
        path: '/changelog',
        name: 'changelog',
        component: () => import(/* webpackChunkName: "group1" */ './pages/Changelog'),
        meta: {
            title: "Change log",
            auth: true
        }
    },
    // {
    //     path: '/user-guide',
    //     name: 'userguide',
    //     component: () => import(/* webpackChunkName: "group1" */ './pages/Userguide'),
    //     meta: {
    //         title: "User guide",
    //         auth: true
    //     }
    // },
    /*
    {
        path: '/500',
        component: resolve => require(['components/errors/Error500'], resolve),
        meta: {
            title: "500",
        }
    },
    */
    // {
    //     path: '*',
    //     component: resolve => require(['./components/errors/Error404'], resolve),
    //     meta: {
    //         title: "404",
    //     }
    // }
    {
      path: '*',
      meta: { title: 'Page not found'},
      beforeEnter: (to, from, next) => {
        console.log('to');
        console.log(to);
        return next({ name: 'home' });
      }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.MIX_ROUTES_BASE || '/',
    //linkActiveClass: "active",
    routes
});

router.beforeEach((to, from, next) => {
    if (to.path === '/') {
      if (!Vue.auth.check()) {
        return next({ name: 'login' });
      }
    }
    //store.commit("routeChange", "start")
    // scroll to top when changing pages
    if (document.scrollingElement) {
        document.scrollingElement.scrollTop = 0
    } else if (document.documentElement) {
        document.documentElement.scrollTop = 0
    }

    store.commit('showWcSelect', to.meta.wcSelect !== false);

    next()
});

// //====change page title after route change

router.afterEach((to, from) => {
    if (to.meta.title) {
        document.title = to.meta.title + " - " + store.site_name
    //    document.title = to.meta.title
        store.commit('changePageTitle', to.meta.title)
    }
    // store.commit("routeChange", "end")
    // if (window.innerWidth <= 992) {
    //     store.commit('toggleSidebar', "close")
    // } else {
    //     store.commit('toggleSidebar', "open")
    // }
});

export default router
