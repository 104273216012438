import "es6-promise/auto";
import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import { EventBus } from "./event-bus.js";

Vue.use(Vuex);

const persistentState = new VuexPersistence({
    storage: window.localStorage,
    key: "grt",
    reducer: state => ({
        currentPages: state.currentPages,
        filterSettings: state.filterSettings
    }),
    filter: mutation =>
        mutation.type == "changeCurrentPage" || mutation.type == "changeFilter"
    // restoreState: (key, storage) => {
    //     var obj = JSON.parse(storage[key])
    //     if ()
    // }
});

//=======vuex store start===========
const store = new Vuex.Store({
    state: {
        siteName: "Shell GRT",
        showMenu: 1,
        showWcSelect: true,
        pageTitle: null,
        user: {},
        ajaxLoading: 0,
        currentPages: {},
        filterSettings: {},
        brands: [],
        allBrands: [],
        loyalties: [],
        allLoyalties: [],
        allOtherText: [],
        allCountries: [],
        workCountry: null,
        lastWorkCountry: null,
        countryRegions: { CAN: null, CHI: null, RUS: null, USA: null }, // 'RBL':null, // code4
        brandsRegions: null,
        targets: [],
        methodologies: [],
        allLanguages: [],
        allEthnicities: [],
        allModulebrands: [],
        allFuelseg1: [],
        allFuelseg2: [],
        allVariableqs: [],
        allVtype: [],
        allQtext: [],
        allRegions: [],
        allRegfilter: [],
        allQuotas: []
    },
    getters: {
        masterBrands: state => {
            return state.brands.filter(
                brand => brand.master_brand_id == brand.id
            );
        },
        subBrands: state => master_brand_id => {
            return state.brands.filter(
                brand =>
                    brand.master_brand_id == master_brand_id &&
                    brand.id != master_brand_id
            );
        },
        brandById: state => brand_id => {
            return state.brands.find(brand => brand.id == brand_id);
        },
        masterBrandOf: state => brand_id => {
            const theBrand = state.brands.find(brand => brand.id == brand_id);
            if (theBrand) {
                if (
                    !theBrand.master_brand_id ||
                    theBrand.master_brand_id === theBrand.id
                )
                    return theBrand;
                return state.brands.find(
                    brand => brand.id == theBrand.master_brand_id
                );
            }
            return null;
        },
        userCountries: state => {
            return state.user ? state.user.countries : null;
        },
        userCountriesExpanded: state => {
            const countries = state.user ? state.user.countries : null;
            return countries !== null ? countries : state.allCountries;
        },
        workCountryRegions: state => {
            return (
                (state.workCountry
                    ? state.countryRegions[state.workCountry.code4]
                    : null) || []
            );
        },
        brandRegionsOf: state => (brand_id, country_code) => {
            const theBrandRegions = state.brandsRegions
                ? state.brandsRegions[brand_id]
                : null;
            if (theBrandRegions) {
                return theBrandRegions[country_code];
            }
            return null;
        }
    },
    mutations: {
        toggleLoading(state, option) {
            if (option == "start") {
                state.ajaxLoading = state.ajaxLoading + 1;
            } else if (option == "stop") {
                if (state.ajaxLoading > 0) {
                    state.ajaxLoading = state.ajaxLoading - 1;
                }
            } else if (option == "toggle") {
                //state.ajaxLoading = !state.ajaxLoading;
            }
            if (state.ajaxLoading) {
                document
                    .getElementsByTagName("body")[0]
                    .classList.add("ajax-loading");
            } else {
                document
                    .getElementsByTagName("body")[0]
                    .classList.remove("ajax-loading");
            }
        },
        routeChange(state, loader) {
            if (loader == "start") {
                state.preloader = true;
            } else if (loader == "end") {
                state.preloader = false;
            }
        },
        changePageTitle(state, title) {
            state.pageTitle = title;
            document.title = title + " - " + state.siteName;
        },
        changeUserData(state, user = {}) {
            // if (user) {
            //     user.picture = user.avatar ? `/storage/${user.avatar}` : '/images/default-profile-img.png'
            // }
            state.user = user || {};
            state.workCountry = null;
            if (user) {
                if (user.role === "local client") {
                    const workCountries = user.countries || [];
                    //console.log('local client workCountries', workCountries);
                    if (workCountries.length === 1) {
                        state.workCountry = workCountries[0];
                    }
                    // else if (workCountries.length > 1) {
                    //     state.workCountry = workCountries[0];
                    // }
                    else {
                        state.workCountry = null;
                    }
                } else {
                    state.workCountry = null;
                }
            } else {
                state.workCountry = null;
            }
        },
        changeCurrentPage(state, data) {
            state.currentPages[data.path] = data.page;
        },
        changeFilter(state, data) {
            state.filterSettings[data.key] = data.filter;
        },
        changeBrands(state, brands) {
            state.brands = (brands || []).filter((brand, index) => {
                const eq =
                    brands.findIndex(brand1 => brand1.id === brand.id) ===
                    index;
                if (!eq) console.log("Filtering out", brand.name);
                return eq;
            });
        },
        changeAllBrands(state, allBrands) {
            state.allBrands = allBrands || [];
        },
        changeLoyalties(state, loyalties) {
            state.loyalties = loyalties || [];
        },
        changeAllLoyalties(state, allLoyalties) {
            state.allLoyalties = allLoyalties || [];
        },
        changeAllOtherText(state, allOtherText) {
            state.allOtherText = allOtherText || [];
        },
        changeAllCountries(state, allCountries) {
            state.allCountries = allCountries || [];
        },
        changeWorkCountry(state, country) {
            // console.log('changeWorkCountry', country);
            state.workCountry = country
                ? {
                      code4: country.code4,
                      name: country.name,
                      group_code4: country.group_code4
                  }
                : null;
        },
        changeCountryRegions(state, data) {
            state.countryRegions[data.country] = data.regions || [];
        },
        changeBrandsRegions(state, data) {
            state.brandsRegions = data.brandsRegions || {};
        },
        changeTargets(state, targets) {
            state.targets = targets || [];
        },
        changeMethodologies(state, methodologies) {
            state.methodologies = methodologies || [];
        },
        changeLanguages(state, languages) {
            state.allLanguages = languages || [];
        },
        changeEthnicities(state, ethnicities) {
            state.allEthnicities = ethnicities || [];
        },
        changeModulebrands(state, modulebrands) {
            state.allModulebrands = modulebrands || [];
        },
        changeFuelseg1(state, fuelseg1) {
            state.allFuelseg1 = fuelseg1 || [];
        },
        changeFuelseg2(state, fuelseg2) {
            state.allFuelseg2 = fuelseg2 || [];
        },
        changeVariableqs(state, variableqs) {
            state.allVariableqs = variableqs || [];
        },
        changeVtype(state, vtype) {
            state.allVtype = vtype || [];
        },
        changeQtext(state, qtext) {
            state.allQtext = qtext || [];
        },
        changeRegions(state, regions) {
            state.allRegions = regions || [];
        },
        changeRegfilter(state, regfilter) {
            state.allRegfilter = regfilter || [];
        },
        changeQuotas(state, quotas) {
            state.allQuotas = quotas || [];
        },
        Menu(state, show) {
            state.showMenu = !!show;
        },
        showWcSelect(state, show) {
            if (state.showWcSelect && !show) {
                state.lastWorkCountry = state.workCountry;
                state.workCountry = null;
            } else if (!state.showWcSelect && show) {
                state.workCountry = state.lastWorkCountry;
            }
            state.showWcSelect = !!show;
        }
    },
    actions: {
        fetchCountryRegions(
            { commit, state },
            { data = undefined, options = {} } = {}
        ) {
            if (options.ifEmpty && state.countryRegions[data.country] !== null)
                return;

            // todo remove this
            // commit('changeCountryRegions', { country: data.country, regions: [{id: 1, name: data.country+'-Reg1'}, {id: 2, name: data.country+'-Reg2'}] }); return;

            return Vue.prototype
                .$ajaxRequest(`/regions/${data.country}`, { method: "get" })
                .then(response => {
                    if (response.data.rows) {
                        commit("changeCountryRegions", {
                            country: data.country,
                            regions: response.data.rows
                        });
                    }
                });
        },
        fetchBrandsRegions(
            { commit, state },
            { data = undefined, options = {} } = {}
        ) {
            if (options.ifEmpty && state.brandsRegions !== null) return;

            // todo remove this
            // commit('changeBrandsRegions', { brandsRegions: {
            //     1: {
            //         'HUN': [],
            //         'CAN': [91,92],
            //         'USA': [801,802,803],
            //     },
            // } }); return;

            return Vue.prototype
                .$ajaxRequest(`/brands/regions/`, { method: "get" })
                .then(response => {
                    if (response.data.rows) {
                        commit("changeBrandsRegions", {
                            brandsRegions: response.data.rows
                        });
                    }
                });
        },
        fetchAllCountries(
            { commit, state },
            { data = undefined, options = {} } = {}
        ) {
            if (
                options.ifEmpty &&
                state.allCountries &&
                state.allCountries.length
            )
                return;

            // return Vue.prototype.$ajaxRequest('/countries', {method: 'get'})
            //     .then(response => {
            //         if (response.data.rows) {
            //             commit('changeAllCountries', response.data.rows);
            //         }
            //     });
            return store.dispatch("fetchAllBaseTables", ["countries"]);
        },
        fetchBrands({ commit }, data) {
            let method = "get";
            if (data && data.options) {
                method = "post";
            }
            return Vue.prototype
                .$ajaxRequest("/brands", { method: method, data: data })
                .then(function(response) {
                    if (response.data.rows) {
                        commit("changeBrands", response.data.rows);
                    }
                });
        },
        fetchLoyalties({ commit }, data) {
            let method = "get";
            if (data && data.options) {
                method = "post";
            }
            return Vue.prototype
                .$ajaxRequest("/loyalties", {
                    method: method,
                    data: { with_countries: 1, ...data }
                })
                .then(function(response) {
                    if (response.data.rows) {
                        commit("changeLoyalties", response.data.rows);
                    }
                });
        },
        fetchAllBrands({ commit }, data) {
            return store.dispatch("fetchAllBaseTables", ["brands"]);
        },
        fetchAllLoyalties({ commit }, data) {
            return store.dispatch("fetchAllBaseTables", ["loyalties"]);
        },
        fetchAllOtherText({ commit }, data) {
            return store.dispatch("fetchAllBaseTables", ["other_text"]);
        },
        fetchAllTargets({ commit }, data) {
            let method = "get";
            if (data && data.options) {
                method = "post";
            }
            return Vue.prototype
                .$ajaxRequest("/targets", { method, data })
                .then(function(response) {
                    if (response.data.rows) {
                        commit("changeTargets", response.data.rows);
                    }
                });
        },
        fetchAllMethodologies({ commit, state }) {
            if (state.allMethodologies) return;

            // return Vue.prototype.$ajaxRequest('/methodologies', {method: 'get', options: { countries: false, userCountries: false } })
            //     .then(response => {
            //         if (response.data.rows) {
            //             commit('changeMethodologies', response.data.rows);
            //         }
            //     });
            return store.dispatch("fetchAllBaseTables", ["methodologies"]);
        },
        fetchAllLanguages({ commit, state }) {
            //if (state.allLanguages) return;

            // return Vue.prototype.$ajaxRequest('/languages', {method: 'get', options: { countries: false, userCountries: false } })
            //     .then(response => {
            //         if (response.data.rows) {
            //             commit('changeLanguages', response.data.rows);
            //         }
            //     });
            return store.dispatch("fetchAllBaseTables", ["languages"]);
        },
        fetchAllEthnicities({ commit, state }) {
            //if (state.allEthnicities) return;

            // return Vue.prototype.$ajaxRequest('/ethnicities', {method: 'get', options: { countries: false, userCountries: false } })
            //     .then(response => {
            //         if (response.data.rows) {
            //             commit('changeEthnicities', response.data.rows);
            //         }
            //     });
            return store.dispatch("fetchAllBaseTables", ["ethnicities"]);
        },
        fetchAllModulebrands({ commit, state }) {
            return store.dispatch("fetchAllBaseTables", ["module_brands"]);
        },
        fetchAllFuelseg1({ commit, state }) {
            //if (state.allFuelseg1) return;

            // return Vue.prototype.$ajaxRequest('/fuelseg1', {method: 'get', options: { countries: false, userCountries: false } })
            //     .then(response => {
            //         if (response.data.rows) {
            //             commit('changeFuelseg1', response.data.rows);
            //         }
            //     });
            return store.dispatch("fetchAllBaseTables", ["fuel_seg1"]);
        },
        fetchAllFuelseg2({ commit, state }) {
            //if (state.allFuelseg2) return;

            // return Vue.prototype.$ajaxRequest('/fuelseg2', {method: 'get', options: { countries: false, userCountries: false } })
            //     .then(response => {
            //         if (response.data.rows) {
            //             commit('changeFuelseg2', response.data.rows);
            //         }
            //     });
            return store.dispatch("fetchAllBaseTables", ["fuel_seg2"]);
        },
        fetchAllVariableqs({ commit, state }) {
            //if (state.allVariableqs) return;

            // return Vue.prototype.$ajaxRequest('/variableqs', {method: 'get', options: { countries: false, userCountries: false } })
            //     .then(response => {
            //         if (response.data.rows) {
            //             commit('changeVariableqs', response.data.rows);
            //         }
            //     });
            return store.dispatch("fetchAllBaseTables", ["variableqs"]);
        },
        fetchAllVtype({ commit, state }) {
            //if (state.allVtype) return;

            // return Vue.prototype.$ajaxRequest('/vtype', {method: 'get', options: { countries: false, userCountries: false } })
            //     .then(response => {
            //         if (response.data.rows) {
            //             commit('changeVtype', response.data.rows);
            //         }
            //     });
            return store.dispatch("fetchAllBaseTables", ["vtype"]);
        },
        fetchAllQtext({ commit, state }) {
            //if (state.allQtext) return;

            // return Vue.prototype.$ajaxRequest('/qtext', {method: 'get', options: { countries: false, userCountries: false } })
            //     .then(response => {
            //         if (response.data.rows) {
            //             commit('changeQtext', response.data.rows);
            //         }
            //     });
            return store.dispatch("fetchAllBaseTables", ["qtext"]);
        },
        fetchAllRegions({ commit, state }) {
            //if (state.allRegions) return;

            // return Vue.prototype.$ajaxRequest('/regions', {method: 'get', options: { countries: false, userCountries: false } })
            //     .then(response => {
            //         if (response.data.rows) {
            //             commit('changeRegions', response.data.rows);
            //         }
            //     });
            return store.dispatch("fetchAllBaseTables", ["regions"]);
        },
        fetchAllRegfilter({ commit, state }) {
            //if (state.allRegfilter) return;

            // return Vue.prototype.$ajaxRequest('/regfilter', {method: 'get', options: { countries: false, userCountries: false } })
            //     .then(response => {
            //         if (response.data.rows) {
            //             commit('changeRegfilter', response.data.rows);
            //         }
            //     });
            return store.dispatch("fetchAllBaseTables", ["reg_filter"]);
        },
        fetchAllQuotas({ commit, state }) {
            return store.dispatch("fetchAllBaseTables", ["quotas"]);
        },
        fetchAllBaseTables({ commit }, tables) {
            return Vue.prototype
                .$ajaxRequest("/basedata", {
                    method: "post",
                    options: { countries: false, userCountries: false },
                    data: {
                        tables: tables || [
                            "countries",
                            "ethnicities",
                            "fuel_seg1",
                            "fuel_seg2",
                            "incomes",
                            "languages",
                            "methodologies",
                            "module_brands",
                            "qtext",
                            "regions",
                            "reg_filter",
                            "variableqs",
                            "vtype",
                            "quotas",
                            "brands",
                            "loyalties",
                            "other_text"
                        ]
                    }
                })
                .then(response => {
                    if (response.data.rows) {
                        const tableToCommit = {
                            countries: "changeAllCountries",
                            ethnicities: "changeEthnicities",
                            fuel_seg1: "changeFuelseg1",
                            fuel_seg2: "changeFuelseg2",
                            incomes: null,
                            languages: "changeLanguages",
                            methodologies: "changeMethodologies",
                            module_brands: "changeModulebrands",
                            qtext: "changeQtext",
                            regions: "changeRegions",
                            reg_filter: "changeRegfilter",
                            variableqs: "changeVariableqs",
                            vtype: "changeVtype",
                            quotas: "changeQuotas",
                            brands: "changeAllBrands",
                            loyalties: "changeAllLoyalties",
                            other_text: "changeAllOtherText"
                        };
                        const tables = response.data.rows;
                        Object.keys(tables).forEach(table => {
                            const commitName = tableToCommit[table];
                            if (commitName) {
                                commit(commitName, tables[table].rows);
                            }
                        });
                    }
                });
        },

        deleteBrand({ commit }, brand) {
            const brandId = brand.id;
            const masterBrandId = brand.master_brand_id;
            //console.log("deleteBrand", JSON.stringify(brand, null, 2));
            return Vue.prototype
                .$ajaxRequest(`/brands/${brand.id}`, { method: "delete" })
                .then(response => {
                    //console.log("EventBus.$emit('brandsChanged'", (masterBrandId == brandId) ? null : masterBrandId);
                    EventBus.$emit(
                        "brandsChanged",
                        masterBrandId == brandId ? null : masterBrandId
                    );
                    return response;
                })
                .catch(() => {
                    // EventBus.$emit('brandsChanged', data.brandId);
                })
                .finally(() => {});
        },
        saveBrand({ commit }, data) {
            //console.log('saveBrand in store');
            const { id: brandId, regions, ...brand } = data;
            const url = brandId ? `/brands/${brandId}` : "/brands/create";
            // Update existing
            if (brandId) {
                if (
                    regions === null ||
                    (regions && Object.keys(regions).length)
                ) {
                    brand.regions = regions;
                    console.log("saveBrand(). Saving regions...", regions);
                }
            }
            return Vue.prototype
                .$ajaxRequest(url, { method: "post", data: brand })
                .then(response => {
                    const id =
                        response.data.brand.master_brand_id ||
                        response.data.brand.id;
                    EventBus.$emit("brandsChanged", id);
                    return response;
                })
                .catch(() => {
                    EventBus.$emit("brandsChanged", brandId);
                })
                .finally(() => {
                    if (brand.regions) {
                        setTimeout(() => {
                            store.dispatch("fetchBrandsRegions", {
                                data: {},
                                options: { ifEmpty: false }
                            });
                        }, 0);
                    }
                });
        }
    }
    //plugins: [persistentState.plugin]
});

//=======vuex store end===========
export default store;
