<template>
    <div class="vld-parent">
      <loading :active.sync="$store.state.ajaxLoading > 0" :is-full-page="true"></loading>
      <div v-if="!$auth.ready()">
          Loading...
      </div>
      <div id="main" v-if="$auth.ready()">
        <header id="header">
          <Menu/>
        </header>
        <b-container id="content" fluid :key="refreshKey">
          <router-view/>
        </b-container>
      </div>
    </div>
</template>

<script>
import '../sass/app.scss';
import Menu from './components/Menu.vue'
import Loading from 'vue-loading-overlay';

export default {
  components: {
    Loading,
    Menu
  },
  data() {
    return {
      refreshKey: 0,
    }
  },
  created() {
    //this.$fetchBrands();
  },
  watch: {
    '$store.state.workCountry': function() {
      // console.log('$store.state.workCountry', this.$store.state.workCountry);
      this.refreshKey++;
    }
  },
}
</script>


<style lang="scss">
//@import '../sass/app.scss';
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body.ajax-loading {
    background: rgb(138, 138, 138) !important;
}
</style>
