<template>
    <div>
        <transition name="fade" mode="in-out">
            <notifications group="main" class="loggedout-notifications" />
        </transition>

        <b-navbar toggleable="lg" type="dark">
            <b-navbar-brand to="/"><img src="/img/logo.png" alt="logo"><img src="/img/grt-logo.png" alt="grt-logo"></b-navbar-brand>

            <b-navbar-toggle v-if="$store.state.showMenu" target="nav-collapse"></b-navbar-toggle>

            <b-collapse v-if="$store.state.showMenu" id="nav-collapse" is-nav>
              <b-navbar-nav v-if="$auth.check()">
                  <b-nav-item to="/target" v-if="$auth.check()">Methodology</b-nav-item>
                  <b-nav-item to="/quota" v-if="$auth.check()">Sample Structure</b-nav-item>
                  <b-nav-item to="/brands" v-if="$auth.check()">Brands</b-nav-item>
                  <b-nav-item to="/loyalties" v-if="$auth.check()">Loyalty Programmes</b-nav-item>
                  <!-- <b-nav-item to="/translations" v-if="$auth.check()">Translations</b-nav-item> -->
                  <b-nav-item-dropdown v-if="$auth.check()">
                    <!-- Using 'button-content' slot -->
                    <template slot="button-content">Translations</template>
                    <b-dropdown-item to="/translations/brands">Brands</b-dropdown-item>
                    <b-dropdown-item to="/translations/loyalties">Loyalty Programmes</b-dropdown-item>
                    <b-dropdown-item to="/translations/other_text">Other</b-dropdown-item>
                    <b-dropdown-item to="/import/other_text_update" v-if="$auth.check('s')">Import Other Update</b-dropdown-item>
                    <b-dropdown-item to="/import/other_text_new" v-if="$auth.check('s')">Import Other New</b-dropdown-item>
                  </b-nav-item-dropdown>
                  <b-nav-item-dropdown v-if="$auth.check()">
                    <!-- Using 'button-content' slot -->
                    <template slot="button-content">Overview</template>
                    <b-dropdown-item to="/overview/brands">Brands</b-dropdown-item>
                    <b-dropdown-item to="/overview/variants">Variants</b-dropdown-item>
                    <b-dropdown-item to="/overview/loyalties">Loyalty Programmes</b-dropdown-item>
                    <!-- <b-dropdown-item to="/overview/approval">Markets approval</b-dropdown-item> -->
                  </b-nav-item-dropdown>
              </b-navbar-nav>

              <b-navbar-nav class="ml-auto" v-if="$auth.check()">
                  <b-nav-item right v-if="$auth.check() && $store.state.showWcSelect">
                    <CountrySelect v-if="$auth.check('akg') || (userCountries && userCountries.length > 1)"
                      v-model="workCountrySelected" placeholder="All countries"
                      :allCountries="selectableCountries"
                      :addAllCountriesItem=true
                      :multiple=false :close-on-select=true :hideSelected=false
                      @input="onInputWorkCountry" />
                    <span v-else>{{workCountry | nameOrEmpty}}</span>
                  </b-nav-item>
              </b-navbar-nav>
              <!-- Right aligned nav items -->
              <b-navbar-nav class="ml-auto" v-if="$auth.check()">
                <!--
                  <b-nav-form>
                  <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
                  <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
                  </b-nav-form>

                  <b-nav-item-dropdown text="Lang" right>
                  <b-dropdown-item href="#">EN</b-dropdown-item>
                  <b-dropdown-item href="#">ES</b-dropdown-item>
                  <b-dropdown-item href="#">RU</b-dropdown-item>
                  <b-dropdown-item href="#">FA</b-dropdown-item>
                  </b-nav-item-dropdown>
                -->
                  <!-- <b-nav-item to="/user-guide" target="userguide" v-if="$auth.check()">User guide</b-nav-item> -->
                  <b-nav-item href="/userguide" target="userguide" v-if="$auth.check()">User guide</b-nav-item>

                  <b-nav-item-dropdown right v-if="$auth.check('s')">
                    <!-- Using 'button-content' slot -->
                    <template slot="button-content">Eclass</template>
                    <b-dropdown-item to="/import/eclass">Import Eclass</b-dropdown-item>
                    <b-dropdown-item to="/export/eclass">Export Eclass</b-dropdown-item>
                  </b-nav-item-dropdown>

                  <b-nav-item-dropdown right v-if="$auth.check('ak')">
                    <template slot="button-content">Admin</template>
                    <b-dropdown-item to="/export/eclass">Export Eclass</b-dropdown-item>
                    <b-dropdown-item @click="exportTranslations(false)">Export Translations</b-dropdown-item>
                    <b-dropdown-item @click="exportTranslations(true)">Export Other</b-dropdown-item>
                    <b-dropdown-item to="/changelog">Change log</b-dropdown-item>
                    <b-dropdown-item to="/users" v-if="$auth.check('a')">Users</b-dropdown-item>
                  </b-nav-item-dropdown>

                  <b-nav-item-dropdown right v-if="$auth.check()">
                    <!-- Using 'button-content' slot -->
                    <template slot="button-content"><em>{{ userName }}</em></template>
                    <b-dropdown-item to="/password-change">Change Password</b-dropdown-item>
                    <b-dropdown-item to="/logout">Log Out</b-dropdown-item>
                  </b-nav-item-dropdown>
              </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
import CountrySelect from '../components/CountrySelect';

export default {
    components: { CountrySelect },
    data() {
      return {
        workCountrySelected: null,
        routes: {
          // UNLOGGED
          unlogged: [
            { name: 'Login', path: 'login'}
          ],
          // LOGGED USER
          user: [
            { name: 'Dashboard', path: 'dashboard' }
          ],
          // LOGGED ADMIN
          admin: [
            { name: 'Dashboard', path: 'admin.dashboard' }
          ]
        }
      }
    },
    computed: {
      userName() {
        return this.$store.state.user.name;
      },
      workCountry() {
        return this.$store.state.workCountry;
      },
      allCountries() {
        return this.$store.state.allCountries;
      },
      userCountries() {
        return this.$store.getters.userCountries;
      },
      selectableCountries() {
        return this.userCountries ? this.userCountries : this.allCountries;
      },
    },
    filters: {
      nameOrEmpty(obj) {
        const objName = obj && obj.name;
        return objName || 'All Countries';
      }
    },
    watch: {
        '$store.state.workCountry': function() {
            this.workCountrySelected = this.workCountry;
        },
    },
    beforeMount() {
      this.fetchAllCountries();
    },
    beforeUpdate() {
      //console.log('auth', this.$auth.verify());
      this.fetchAllCountries();
    },
    methods: {
      fetchAllCountries() {
        if (this.$auth.check()) {
          this.$store.dispatch('fetchAllCountries', { options: { ifEmpty: true } });
        }
      },
      async onInputWorkCountry(v) {
        this.$store.commit('changeWorkCountry', v);
        if (v) {
          await this.$store.dispatch('fetchBrandsRegions', { data: {}, options: { ifEmpty: false } });
          await this.$store.dispatch('fetchCountryRegions', { data: { country: v.code4 }, options: { ifEmpty: true } });
        }
      },
      async exportTranslations(other) {
        const options = !other ? {
          question: 'Export translations?',
          url: '/export/translations',
          filename: 'translations'
        } : {
          question: 'Export other translations?',
          url: '/export/other',
          filename: 'other'
        };
        const confirmed = await this.confirm(options.question, '', 'question');
          if (confirmed) {
              console.log('exportTranslations');
              return this.$ajaxRequest(options.url, { method: 'post', responseType: 'blob', data: {
                    countries: null
                  }})
                  .then(response => {
                    console.log(response.headers['content-disposition']);
                    console.log(response.request.getResponseHeader('Content-Disposition'));
                    const disposition = response.request.getResponseHeader('Content-Disposition')
                    let fileName = options.filename + ".xlsx";
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) {
                        fileName = matches[1].replace(/['"]/g, '');
                    }
                    if (!window.navigator.msSaveOrOpenBlob){
                      // BLOB NAVIGATOR
                      const url = window.URL.createObjectURL(new Blob([response.data]));
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute('download', fileName);
                      document.body.appendChild(link);
                      link.click();
                    }else{
                      // BLOB FOR EXPLORER 11
                      const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), fileName);
                    }
                  })
                  .catch(error => {
                    this.$swal.fire({
                        title: 'Failed',
                        text: 'An error occured while downloading the translations.',
                        type: 'error',
                        confirmButtonText: 'Close',
                    });
                    console.log('Error', error);
                  });
          }
      },
      async confirm(text, title = 'Are you sure?', type = 'warning', confirmButtonText = 'Yes', cancelButtonText = 'No') {
          return this.$swal.fire({
              title,
              text,
              type,
              showCancelButton: true,
              confirmButtonText,
              cancelButtonText
          }).then((result) => {
              if (result.value) {
                  return true;
              }
              return false;
          }).catch(() => {
              return false;
          });
      },

    },
}
</script>

<style lang="scss" scoped>
.navbar-dark {
  //background: #f48c68;
  //background: #404040;
  background: #000000;

  .navbar-nav .nav-link {
    color: #ffffff;
    &.disabled {
      color: rgba(255, 255, 255, 0.50);
    }
    &.router-link-active,
    &.router-link-exact-active {
      font-weight: bold;
      text-decoration: underline !important;
    }
  }
}

.navbar-brand {
  padding: 0;
  img {
    height: 40px;
    margin-left: 8px;
  }
}
</style>
