/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import 'es6-promise/auto'
import axios from 'axios'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import Swal from 'sweetalert2';
//require('./raphael-min.js');
//require('./livicons-1.4.min.js');
// you can include livicons using but after rendering your page, call this js code
// $('.livicon').each(function(){
//                     $(this).updateLivicon();
//                 });

import VueAuth from '@websanova/vue-auth'
import VueAxios from 'vue-axios'
import VueRouter from 'vue-router'
import ToggleButton from 'vue-js-toggle-button'
import Notifications from 'vue-notification'
import Multiselect from 'vue-multiselect'
import VueSweetalert2 from 'vue-sweetalert2'
import VueGoogleCharts from 'vue-google-charts'

import Utils from './utils'
import App from './Application.vue'
import auth from './auth'
import router from './router'
import store from './store'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'vue-loading-overlay/dist/vue-loading.css'
//import 'font-awesome/css/font-awesome.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'animate.css'
//import './assets/sass/index.sass' 
//import '../sass/app.scss'

import QuickEdit from 'vue-quick-edit';

Vue.component('quick-edit', QuickEdit);

axios.interceptors.response.use(
  response => {
    //console.log(response);
    return response;
  },
  error => {
    // console.log('ERROR INTERCEPTOR');
    // console.log(error.response);
    const {status} = error.response;
    if (status === 401 || status === 401) {
      //Vue.auth.logout({makeRequest: false, redirect: null});
    }
    return Promise.reject(error);
  }
);

window.Vue = Vue;
window.Swal = Swal;
Vue.use(BootstrapVue)

Vue.router = router
//Vue.use(VueRouter)

Vue.use(VueAxios, axios)
axios.defaults.baseURL = `${process.env.MIX_APP_URL}/api/v1`
//axios.defaults.baseURL = `/api/v1`
if ( process.env.NODE_ENV == 'production' ) {
  //
} else {
  //
}
console.log('axios.defaults.baseURL:', axios.defaults.baseURL);
if (!process.env.MIX_APP_URL) {
  axios.defaults.baseURL = `/api/v1`;
  console.log('MIX_APP_URL', MIX_APP_URL);
}
console.log('axios.defaults.baseURL:', axios.defaults.baseURL);

Vue.use(VueAuth, auth)
Vue.use(ToggleButton)
Vue.use(Notifications)
Vue.use(VueSweetalert2);
Vue.component('multiselect', Multiselect)
Vue.use(Utils)
Vue.use(VueGoogleCharts)

Vue.config.productionTip = false

const check0 = Vue.auth.check;
// Vue.auth.check = function(arg1) {
//   const ad = 'admin';
//   const sa = 'super ' + ad;
//   let ret = check0.apply(this, arguments);
//   if (!ret) {
//     console.log('a1', arg1);
//     if (arg1 === ad) {
//       ret = check0.call(this, sa);
//     } else if (Array.isArray(arg1) && arg1.includes(ad)) {
//       ret = check0.call(this, sa);
//     }
//   }
//   return ret;
// }

const ad = 'admin';
const sa = 'super ' + ad;
const rolesMap = {
  a: ad + ',' + sa,
  s: sa,
  k: 'kantar',
  g: 'global client',
  l: 'local client',
};

Vue.auth.check = function(arg0) {
  let ret = check0.apply(this, arguments);
  if (!ret && arg0 && !Array.isArray(arg0)) {
    arg0 = arg0.split('').map(a => (rolesMap[a] || '~')).join(',').split(',');
    ret = check0.call(this, arg0);
  }
  return ret;
}

// Auto-register components
//const files = require.context('./components', true, /\.vue$/i)
//files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
